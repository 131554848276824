import axiosInstance from "@/axios";

export default {
    deleteSearch(s_id) {
        console.debug("AdminFactory deleteSearch");
        return new Promise((resolve, reject) => {
            axiosInstance.delete("/admin/searches", {
                data: {
                    sid: s_id,
                }
            })
                .then(response => {
                    console.debug("deleteSearch response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("deleteSearch error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    recache() {
        console.debug("AdminFactory recache");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/cache/all")
                .then(response => {
                    console.debug("recache response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("recache error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    recacheAnalytics() {
        console.debug("AdminFactory recacheAnalytics");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/cache/graphdata")
                .then(response => {
                    console.debug("recacheAnalytics response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("recacheAnalytics error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    deleteAnalyticsCache() {
        console.debug("AdminFactory deleteAnalyticsCache");
        return new Promise((resolve, reject) => {
            axiosInstance.delete("/cache/graphdata")
                .then(response => {
                    console.debug("deleteAnalyticsCache response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("deleteAnalyticsCache error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getLoginInfo() {
        console.debug("AdminFactory getLoginInfo");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/admin/login/info")
                .then(response => {
                    console.debug("getLoginInfo response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getLoginInfo error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    bundle() {
        console.debug("AdminFactory bundle");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/cyberlocker/bundle")
                .then(response => {
                    console.debug("bundle response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("bundle error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    recacheSpecific(assetId) {
        console.debug("AdminFactory recacheSpecific");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/cache/specific?aid=" + assetId)
                .then(response => {
                    console.debug("recacheSpecific response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("recacheSpecific error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getHealthCheckData() {
        console.debug("AdminFactory getHealthCheckData");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/admin/healthcheck")
                .then(response => {
                    console.debug("getHealthCheckData response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getHealthCheckData error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getDomainLookup(domain) {
        console.debug("AdminFactory domainLookup");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/admin/domainlookup?domain=" + domain)
                .then(response => {
                    console.debug("domainLookup response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("domainLookup error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getUsersForJobs() {
        console.debug("AdminFactory getUsersForJobs");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/admin/alljobusers")
                .then(response => {
                    console.debug("getUsersForJobs response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getUsersForJobs error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getTypesForJobs() {
        console.debug("AdminFactory getTypesForJobs");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/admin/alljobtypes")
                .then(response => {
                    console.debug("getTypesForJobs response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getTypesForJobs error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getJobOverviewData(jobSearchData) {
        console.debug("AdminFactory getJobOverviewData");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/admin/joboverview", jobSearchData)
                .then(response => {
                    console.debug("getJobOverviewData response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getJobOverviewData error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    exportJobOverviewData(jobSearchData) {
        console.debug("AdminFactory exportJobOverviewData");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/admin/joboverview/export", jobSearchData)
                .then(response => {
                    console.debug("exportJobOverviewData response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("exportJobOverviewData error: ", error);
                    reject(error); // Reject with the error
                })
        });
    }
}
